export const FETCH_TODO = 'FETCH_TODO'
export const FETCH_TODO_SUCCESS = 'FETCH_TODO_SUCCESS'
export const SET_MAIN_ITEM = 'SET_MAIN_ITEM'
export const SET_LOADING = 'SET_LOADING'
export const SET_PHOTO_CONDITION = 'SET_PHOTO_CONDITION'
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const SET_PD = 'SET_PD'
export const SET_PHOTOSIZE = 'SET_PHOTOSIZE'
export const SET_PHOTO_ROTATION = 'SET_PHOTO_ROTATION'
export const SET_LEFT_MARK = 'SET_LEFT_MARK'
export const SET_RIGHT_MARK = 'SET_RIGHT_MARK'
export const SET_DEFAULT = 'SET_DEFAULT'
